export const modalTitle = {
    deleteCategory: "Delete Category",
  deleteServiceCategory: "Delete Service Category",
    deleteTradePeople: "Delete Trade Person",
    deleteUser:"Delete User",
    deleteTradeServices:"Delete Service"
    
  };
  
  export const modalBody = {
    deleteCategory: "Are you sure you want to delete this category?",
    deleteServiceCategory: "Are you sure you want to delete this category?",
    deleteTradePeople: "Are you sure you want to delete this Trade Person?",
    deleteUser:"Are you sure you want to delete this user?",
    deleteTradeServices:"Are you sure you want to delete this service?"
     
  };