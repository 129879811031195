import React from 'react'
import Layout from '../../layout/Layout'

const EnquiryViews = () => {
  return (
    <Layout>
    <div>page not provided</div>
    </Layout>
  )
}

export default EnquiryViews