import React, { useEffect } from "react";
import Layout from "../components/layout/Layout";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllSearches } from "../redux/Action/AdminAction";
import moment from "moment";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import Search from "../components/search/Search";
const AllSearches = () => {
  const limit = 9;
  const [page, setPage] = useState();
  const [search, setSearch] = useState();
  const dispatch = useDispatch();
  const allUsers = useSelector((state) => state?.adminReducer?.all_searches);
  const totalUser = useSelector((state) => state?.adminReducer?.totalSearches);
  console.log(allUsers,totalUser,'allUsers');
  useEffect(() => {
    dispatch(getAllSearches(page || 1, limit, search));
  }, [page, limit, search]);
  const handlePageClick = (e) => {
    setPage(e?.selected + 1);
  };
  return (
    <Layout>
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          {/* <!-- start page title --> */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <div>
                  <span className="small_text">Smart Choice Traders</span>
                  <h4 className="mb-sm-0 font-size-28">
                    All Searches
                    {/* <span className="green-top-text">{totalUser}</span> */}
                  </h4>
                </div>

                <div className="page-title-right">
                
                  <Search setSearch={setSearch} setPage={setPage} />
                </div>
              </div>
            </div>
          </div>
          {/* <!-- end page title --> */}

          <div className="row">
            <div className="col-xl-12">
              <div className="members_tbl">
                <div className="card">
                  <div className="card-body height_fix">
                    <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                      <h4 className="title_text">All Searches</h4>
                    </div>
                    <div className="table-responsive height-fix-table">
                      <table
                        id="datatable"
                        className="table dt-responsive dealers_table nowrap w-100"
                      >
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Time</th>
                            <th>User Name</th>
                            <th>Service</th>
                            <th>Location</th>
                            <th>No. of Trades</th>
                          </tr>
                        </thead>

                        <tbody className="td_color">
                          {allUsers?.length>0?
                            allUsers?.map((item) => {
                              return (
                                <tr>
                                  <td> {moment(item?.createdAt).format(
                                      "DD/MM/YYYY"
                                    )}</td>
                                  <td>
                                  {moment(item?.createdAt).format(
                                      "hh:mm a"
                                    )}
                                  </td>
                                  <td>
                                  {item?.userId?.firstName+" "+item?.userId?.lastName}
                                  </td>
                                  <td>{item?.serviceName}</td>
                                  <td>
                                    {item?.location}
                                  </td>
                                  {/* <td>{item?.address.split(",")[3]}</td> */}
                                  <td>{item?.no_of_tradeperson}</td>
                                  
                                </tr>
                              );
                            }):<td colspan="10">
                            <p className="no_content_table">No Searches yet</p>
                          </td>}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="pagination_new">
                  <ReactPaginate
                    activeClassName="active"
                    nextLabel="Next"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={1}
                    pageCount={totalUser / limit}
                    previousLabel="Prev"
                    renderOnZeroPageCount={null}
                    containerClassName="pagination"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- container-fluid --> */}
      </div>
      {/* <!-- End Page-content --> */}
    </div>
  </Layout>
  )
}

export default AllSearches