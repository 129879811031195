import React, { useEffect } from "react";
import Layout from "../components/layout/Layout";
import SearchEnquiryChart from "../components/dashboard_charts/SearchenquiryChart";
import ServiceRequest from "../components/dashboard_charts/ServiceRequest";
import CustomerLogin from "../components/dashboard_charts/CustomerLogin";
import CustomerLength from "../components/dashboard_charts/CustomerLength";
import TradepersonLoginTimes from "../components/dashboard_charts/TradespersonLogintimes";
import TradepersonLengthTimes from "../components/dashboard_charts/TradespersonLengthTime ";
import CustomerAccountDeletion from "../components/dashboard_charts/CustomerAccountDeletion ";
import CustomerSignup from "../components/dashboard_charts/CustomerSignup";
import TradepersonSignup from "../components/dashboard_charts/TradepersonSignup";
import ComplaintCategories from "../components/dashboard_charts/ComplaintCategories";
import TradespeopleReview from "../components/dashboard_charts/TradepeopleReviews";
import CustomerReview from "../components/dashboard_charts/CustomerReviews";
import ServiceRequestDays from "../components/dashboard_charts/ServiceRequestDays";
import ServiceRequestLocation from "../components/dashboard_charts/ServiceRequestLocation";
import { useDispatch, useSelector } from "react-redux";
import { dashboardData } from "../redux/Action/AdminAction";

const Dashboard = () => {
  const dispatch = useDispatch();
  const dashboard = useSelector((state) => state?.adminReducer?.dashboard);

  useEffect(()=>{
    dispatch(dashboardData())
  },[])

  console.log(dashboard,"dashboard");

  return (
    <Layout>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* <!-- start page title --> */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <div>
                    <span className="small_text">Smart Choice Traders</span>
                    <h4 className="mb-sm-0 font-size-28">Dashboard </h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="card">
                <div className="row">
                  <div className="col-md-12 mt-4">
                    <div className="card">
                      <div className="card-body h160">
                        <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                          <h4 className="title_text">
                            Account deletion intelligience{" "}
                          </h4>
                        </div>
                        <div className="row">
                          <div className="col-md-3">
                            <div className="bglight dasb_text">
                              <h2>Too much spam </h2>
                              <p className="dasb_text_p"></p>
                              <p className="dasb_textlastp">0</p>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="bglight dasb_text">
                              <h2>Never signed up for this website </h2>
                              <p className="dasb_text_p"></p>
                              <p className="dasb_textlastp">0</p>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="bglight dasb_text">
                              <h2>Not enough tradespeople </h2>
                              <p className="dasb_text_p"> </p>
                              <p className="dasb_textlastp">0</p>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="bglight dasb_text">
                              <h2>I no longer need this service </h2>
                              <p className="dasb_text_p"> </p>
                              <p className="dasb_textlastp">0</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-body h160">
                        <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                          <h4 className="title_text">Complaint Status </h4>
                        </div>
                        <div className="row">
                          <div className="col">
                            <div className="bglight dasb_text">
                              <h2>Initiatied </h2>

                              <p className="dasb_textlastp">0</p>
                            </div>
                          </div>
                          <div className="col">
                            <div className="bglight dasb_text">
                              <h2>Under Investigation </h2>

                              <p className="dasb_textlastp">0</p>
                            </div>
                          </div>
                          <div className="col">
                            <div className="bglight dasb_text">
                              <h2>Closed</h2>

                              <p className="dasb_textlastp">0</p>
                            </div>
                          </div>

                          <div className="col">
                            <div className="bglight dasb_text">
                              <h2>Upheld</h2>

                              <p className="dasb_textlastp">0</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-4">
                <div className="card">
                  <div className="card-body h160">
                    <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                      <h4 className="title_text">Offers</h4>
                      <span>
                        <div className="dropdown_custom ">
                          <div className="dropdown">
                            <button
                              className="p-0 btn-custom-drop dropdown-toggle"
                              type="button"
                              id="dropdownMenuButton"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Lifetime <i className="mdi mdi-menu-down"></i>
                            </button>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuButton"
                              style={{ margin: "0px" }}
                            >
                              <a className="dropdown-item" href="#">
                                {" "}
                                Lifetime
                              </a>
                            </div>
                          </div>
                        </div>
                      </span>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="bg_dasg_ble bgblue dasb_text">
                          <h1>31,123</h1>
                          <p className="mb-2">
                            ****
                            <br />
                            &nbsp;
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="bg_dasg_ble bgyelow dasb_text">
                          <h1>12,123</h1>
                          <p className="mb-2">
                            **** <br />
                            &nbsp;
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-body h160">
                        <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                          <h4 className="title_text">Quote / Estimate </h4>
                        </div>
                        <div className="row">
                          <div className="col">
                            <div className="bglight dasb_text">
                              <h2>Quotes issued </h2>

                              <p className="dasb_textlastp">0</p>
                            </div>
                          </div>
                          <div className="col">
                            <div className="bglight dasb_text">
                              <h2>Estimates issued</h2>

                              <p className="dasb_textlastp">0</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-body h160">
                        <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                          <h4 className="title_text">
                            Complaints registered against tradesperson{" "}
                          </h4>
                        </div>
                        <div className="row">
                          <div className="col">
                            <div className="bglight dasb_text">
                              <h2>monthly </h2>
                              <p className="dasb_text_p"></p>
                              <p className="dasb_textlastp">0</p>
                            </div>
                          </div>
                          <div className="col">
                            <div className="bglight dasb_text">
                              <h2>quarterly</h2>
                              <p className="dasb_text_p"></p>
                              <p className="dasb_textlastp">0</p>
                            </div>
                          </div>
                          <div className="col">
                            <div className="bglight dasb_text">
                              <h2>annually</h2>
                              <p className="dasb_text_p"></p>
                              <p className="dasb_textlastp">0</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-body h160">
                        <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                          <h4 className="title_text">Customer sign up days </h4>
                        </div>
                        <div className="row">
                          <div className="col">
                            <div className="bglight dasb_text">
                              <h2>Monday </h2>
                              <p className="dasb_textlastp">0</p>
                            </div>
                          </div>
                          <div className="col">
                            <div className="bglight dasb_text">
                              <h2>Tuesday</h2>
                              <p className="dasb_textlastp">0</p>
                            </div>
                          </div>
                          <div className="col">
                            <div className="bglight dasb_text">
                              <h2>Wednesday</h2>
                              <p className="dasb_textlastp">0</p>
                            </div>
                          </div>
                          <div className="col">
                            <div className="bglight dasb_text">
                              <h2>Thursday</h2>
                              <p className="dasb_textlastp">0</p>
                            </div>
                          </div>
                          <div className="col">
                            <div className="bglight dasb_text">
                              <h2>Friday</h2>
                              <p className="dasb_textlastp">0</p>
                            </div>
                          </div>
                          <div className="col">
                            <div className="bglight dasb_text">
                              <h2>Saturday</h2>
                              <p className="dasb_textlastp">0</p>
                            </div>
                          </div>
                          <div className="col">
                            <div className="bglight dasb_text">
                              <h2>Sunday</h2>
                              <p className="dasb_textlastp">0</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="col-lg-6">
                  <div className="histogram-chat">
                    <h2>Search enquiry information</h2>
                    <SearchEnquiryChart />
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="col-lg-12 mt-4">
                  <div className="histogram-chat">
                    <h2>Service requests</h2>
                    <div className="row">
                      <div className="col-lg-6">
                        <h2>Location </h2>
                        <ServiceRequest />
                      </div>
                      <div className="col-lg-6">
                        <h2>Days of the week </h2>
                        <ServiceRequestDays />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="row">
                      <div className="col-md-12 mt-4">
                        <div className="card">
                          <div className="card-body h160">
                            <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                              <h4 className="title_text">Search enquiries</h4>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="bglight dasb_text">
                                  <h2>Service </h2>
                                  <p className="dasb_text_p">Total </p>
                                  <p className="dasb_textlastp">0</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mt-4">
                        <div className="card">
                          <div className="card-body h160">
                            <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                              <h4 className="title_text">Location</h4>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="bglight dasb_text">
                                  <h2>Service </h2>
                                  <p className="dasb_text_p">Total </p>
                                  <p className="dasb_textlastp">0</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 mt-4">
                    <div className="histogram-chat">
                      <h2>Location </h2>
                      <ServiceRequestLocation />
                    </div>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="row">
                  <div className="col-lg-6 mt-4">
                    <div className="card">
                      <div className="histogram-chat">
                        <h2>Customer Login times</h2>
                        <CustomerLogin />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 mt-4">
                    <div className="card">
                      <div className="histogram-chat">
                        <h2>Customer Length of time on site</h2>
                        <CustomerLength />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="row">
                  <div className="col-lg-6 mt-4">
                    <div className="card">
                      <div className="histogram-chat">
                        <h2>Tradesperson Login times</h2>
                        <TradepersonLoginTimes />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 mt-4">
                    <div className="card">
                      <div className="histogram-chat">
                        <h2>Tradesperson Length of time on site</h2>
                        <TradepersonLengthTimes />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="row">
                  <div className="col-lg-6 mt-4">
                    <div className="histogram-chat">
                      <h2>Customer account deletion </h2>
                      <CustomerAccountDeletion />
                    </div>
                  </div>
                  <div className="col-lg-6 mt-4">
                    <div className="card">
                      <div className="card-body h160">
                        <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                          <h4 className="title_text">
                            Customer account deletion
                          </h4>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="bglight dasb_text">
                              <h2>Total </h2>
                              <p className="dasb_text_p"> </p>
                              <p className="dasb_textlastp">0</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card px-2 py-2">
                <div className="row">
                  <div className="col-md-6 ">
                    <div className="card">
                      <div className="card-body h160">
                        <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                          <h4 className="title_text">Message exchange </h4>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="bglight dasb_text">
                              <h2>monthly </h2>

                              <p className="dasb_textlastp">0</p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="bglight dasb_text">
                              <h2>quarterly </h2>

                              <p className="dasb_textlastp">0</p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="bglight dasb_text">
                              <h2>annually </h2>

                              <p className="dasb_textlastp">0</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-body h160">
                        <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                          <h4 className="title_text">
                            Average message exchange{" "}
                          </h4>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="bglight dasb_text">
                              <h2>monthly </h2>

                              <p className="dasb_textlastp">0</p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="bglight dasb_text">
                              <h2>quarterly </h2>

                              <p className="dasb_textlastp">0</p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="bglight dasb_text">
                              <h2>annually </h2>

                              <p className="dasb_textlastp">0</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-body h160">
                        <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                          <h4 className="title_text">
                            Message exchange during live booking{" "}
                          </h4>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="bglight dasb_text">
                              <h2>monthly </h2>

                              <p className="dasb_textlastp">0</p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="bglight dasb_text">
                              <h2>quarterly </h2>

                              <p className="dasb_textlastp">0</p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="bglight dasb_text">
                              <h2>annually </h2>

                              <p className="dasb_textlastp">0</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="row">
                  <div className="col-lg-6 mt-4">
                    <div className="histogram-chat">
                      <h2>Customer sign ups</h2>
                      <CustomerSignup />
                    </div>
                  </div>
                  <div className="col-md-6 mt-4">
                    <div className="card">
                      <div className="card-body h160">
                        <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                          <h4 className="title_text">Customer sign ups Data</h4>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="bglight dasb_text">
                              <h2>monthly </h2>
                              <p className="dasb_text_p"> </p>
                              <p className="dasb_textlastp">0</p>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="bglight dasb_text">
                              <h2>quarterly </h2>
                              <p className="dasb_text_p"> </p>
                              <p className="dasb_textlastp">0</p>
                            </div>
                          </div>
                          <div className="col-md-6 mt-4">
                            <div className="bglight dasb_text">
                              <h2>annually </h2>
                              <p className="dasb_text_p"> </p>
                              <p className="dasb_textlastp">0</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="row">
                  <div className="col-lg-6 mt-4">
                    <div className="histogram-chat">
                      <h2>Tradesperson sign up </h2>
                      <TradepersonSignup />
                    </div>
                  </div>
                  <div className="col-md-6 mt-4">
                    <div className="card">
                      <div className="card-body h160">
                        <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                          <h4 className="title_text">
                            Tradesperson sign ups Data
                          </h4>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="bglight dasb_text">
                              <h2>monthly </h2>
                              <p className="dasb_text_p"> </p>
                              <p className="dasb_textlastp">0</p>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="bglight dasb_text">
                              <h2>quarterly </h2>
                              <p className="dasb_text_p"> </p>
                              <p className="dasb_textlastp">0</p>
                            </div>
                          </div>
                          <div className="col-md-6 mt-4">
                            <div className="bglight dasb_text">
                              <h2>annually </h2>
                              <p className="dasb_text_p"> </p>
                              <p className="dasb_textlastp">0</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="row">
                  <div className="col-lg-6 mt-4">
                    <div className="histogram-chat">
                      <h2>Complaint categories </h2>
                      <ComplaintCategories />
                    </div>
                  </div>
                  <div className="col-md-6 mt-4">
                    <div className="card">
                      <div className="card-body h160">
                        <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                          <h4 className="title_text">
                            Complaint categories Data
                          </h4>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="bglight dasb_text">
                              <h2>Monthly </h2>
                              <p className="dasb_text_p"> </p>
                              <p className="dasb_textlastp">0</p>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="bglight dasb_text">
                              <h2>quarterly </h2>
                              <p className="dasb_text_p"> </p>
                              <p className="dasb_textlastp">0</p>
                            </div>
                          </div>
                          <div className="col-md-6 mt-4">
                            <div className="bglight dasb_text">
                              <h2>annually </h2>
                              <p className="dasb_text_p"> </p>
                              <p className="dasb_textlastp">0</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="card">
                <div className="col-md-12 mt-4">
                  <div className="card">
                    <div className="card-body h160">
                      <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                        <h4 className="title_text">Customer sign up times</h4>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="bglight dasb_text">
                            <h2>lunchtime </h2>
                            <p className="dasb_text_p"></p>
                            <p className="dasb_textlastp">0</p>
                          </div>
                        </div>
                        <div className="col">
                          <div className="bglight dasb_text">
                            <h2>evening</h2>
                            <p className="dasb_text_p"></p>
                            <p className="dasb_textlastp">0</p>
                          </div>
                        </div>
                        <div className="col">
                          <div className="bglight dasb_text">
                            <h2>night</h2>
                            <p className="dasb_text_p"></p>
                            <p className="dasb_textlastp">0</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- end page title --> */}
            {/* <!-- dashboard first row start --> */}
            <div className="row mt-4">
              <div className="card">
                <div className="row">
                  <div className="col-md-6 mt-4">
                    <div className="card">
                      <div className="card-body h160">
                        <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                          <h4 className="title_text">Tradespeople reviews </h4>
                        </div>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="bglight dasb_text">
                              <h2>month </h2>
                              <p className="dasb_textlastp">0</p>
                            </div>
                          </div>
                          <div className="col-lg-12 mt-4">
                            <div className="bglight dasb_text">
                              <h2>quarters</h2>
                              <p className="dasb_textlastp">0</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="histogram-chat">
                      <h2>Tradespeople 'review categories' </h2>
                      <TradespeopleReview />
                    </div>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="row">
                  <div className="col-md-6 mt-4">
                    <div className="card">
                      <div className="card-body h160">
                        <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                          <h4 className="title_text">Customer reviews </h4>
                        </div>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="bglight dasb_text">
                              <h2>month </h2>
                              <p className="dasb_textlastp">0</p>
                            </div>
                          </div>
                          <div className="col-lg-12 mt-4">
                            <div className="bglight dasb_text">
                              <h2>quarters</h2>
                              <p className="dasb_textlastp">0</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 mt-4">
                    <div className="histogram-chat">
                      <h2>Customer 'review categories' </h2>
                      <CustomerReview />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="col-md-12 mt-4">
                  <div className="card">
                    <div className="card-body h160">
                      <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                        <h4 className="title_text">Response rate </h4>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="bglight dasb_text">
                            <h2>Response rate </h2>
                            <p className="dasb_textlastp">
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s,
                            </p>
                          </div>
                        </div>
                        <div className="col">
                          <div className="bglight dasb_text">
                            <h2>Technology</h2>
                            <p className="dasb_textlastp">
                              {" "}
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s,
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="col-md-12 mt-4">
                  <div className="card">
                    <div className="card-body h160">
                      <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                        <h4 className="title_text">Location </h4>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="bglight dasb_text">
                            <h2>Location Data of tradesperson </h2>
                            <p className="dasb_textlastp">
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s,
                            </p>
                          </div>
                        </div>
                        <div className="col">
                          <div className="bglight dasb_text">
                            <h2>Location Data of customers</h2>
                            <p className="dasb_textlastp">
                              {" "}
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s,
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- dashboard first row end --> */}

            {/* <div className="row">
              <div className="col-md-6">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                      <h4 className="title_text ff-inter">Top 25 </h4>

                      <span>
                        <div className="searcg_icon">
                          <form className="app-search d-none d-lg-block ">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="position-relative w-0">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search"
                                />
                                <span className="bx bx-search-alt cgrey"></span>
                              </div>
                            </div>
                          </form>
                        </div>
                      </span>
                    </div>

                    <div className="tab_le_dealer">
                      <div className="table-responsive">
                        <table
                          id=""
                          className="table dt-responsive ff-inter active_dealer nowrap w-0"
                        >
                          <thead>
                            <tr>
                              <th>Lorem</th>
                              <th>Lorem</th>
                              <th>Lorem </th>
                              <th>Lorem</th>
                            </tr>
                          </thead>
                          <tbody className="td_color">
                            <tr>
                              <td>1564</td>
                              <td>Name</td>
                              <td>London</td>
                              <td>124</td>
                            </tr>

                            <tr>
                              <td>51568</td>
                              <td>Name</td>
                              <td>Newcastle</td>
                              <td>189</td>
                            </tr>
                            <tr>
                              <td>51568</td>
                              <td>Name</td>
                              <td>Newcastle</td>
                              <td>189</td>
                            </tr>
                            <tr>
                              <td>51568</td>
                              <td>Name</td>
                              <td>Newcastle</td>
                              <td>189</td>
                            </tr>
                            <tr>
                              <td>51568</td>
                              <td>Name</td>
                              <td>Newcastle</td>
                              <td>189</td>
                            </tr>

                            <tr>
                              <td>1564</td>
                              <td>Name</td>
                              <td>Carlisle</td>
                              <td>124</td>
                            </tr>

                            <tr>
                              <td>1564</td>
                              <td>Name</td>
                              <td>London</td>
                              <td>50</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-body">
                        <div className="garph_1">
                          <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                            <h4 className="title_text">Statistics</h4>
                          </div>
                          <div className="days_moth d-block">
                            <ul className="nav nav-pills">
                              <li className="nav-item">
                                <a className="nav-link" href="#">
                                  Day
                                </a>
                              </li>
                              <li className="nav-item">
                                <a className="nav-link" href="#">
                                  Week
                                </a>
                              </li>
                              <li className="nav-item">
                                <a className="nav-link active" href="#">
                                  Month
                                </a>
                              </li>
                            </ul>
                          </div>

                          <div className="tot_trd_vech pt-3 d-block">
                            <p className="mb-2">Total Traded Sales</p>
                            <h5>
                              <b>1724</b>
                            </h5>
                          </div>

                          <div
                            id="spline_area"
                            className="apex-charts"
                            dir="ltr"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-body">
                        <div className="garph_2">
                          <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                            <h4 className="title_text">Statistics</h4>
                          </div>
                          <div className="days_moth">
                            <ul className="nav nav-pills">
                              <li className="nav-item">
                                <a className="nav-link" href="#">
                                  Day
                                </a>
                              </li>
                              <li className="nav-item">
                                <a className="nav-link" href="#">
                                  Week
                                </a>
                              </li>
                              <li className="nav-item">
                                <a className="nav-link active" href="#">
                                  Month
                                </a>
                              </li>
                            </ul>
                          </div>
                          <div className="tot_trd_vech pt-3">
                            <p className="mb-2">Total Underwrite Sales</p>
                            <h5>
                              <b>1724</b>
                            </h5>
                          </div>

                          <div
                            id="spline_area1"
                            className="apex-charts"
                            dir="ltr"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          {/* <!-- container-fluid --> */}
        </div>
        {/* <!-- End Page-content --> */}
      </div>
    </Layout>
  );
};

export default Dashboard;
