import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getWalletTrasnsationsAdmin } from "../../redux/Action/AdminAction";
import { useParams } from "react-router-dom";
import { useState } from "react";
import moment from "moment";

const Wallet = () => {
    const [totalPending,setTotalPending]=useState()
    const { id } = useParams();
  const dispatch = useDispatch(); 
  const walletList=useSelector((state)=>state?.adminReducer?.walletList)
  console.log(walletList,"walletList");
  useEffect(() => {
    dispatch(getWalletTrasnsationsAdmin(id));
  }, []);
  useEffect(()=>{
    if(walletList){
        const total = walletList?.reduce((acc, obj) => {
            return parseFloat(acc) + parseFloat(obj.amount);
          }, 0);
        setTotalPending(total)
     }
  },[walletList])
  return (
    <>
      <div className="row d-flex align-items-center">
        <div className="col-xl-8">
          {/* <div className="availability-funds">
            <ul>
              <li className="balance-bg-blue">
                <div>
                  <h1 className="blue-text-color">£0.00</h1>
                  <p class="dasb_text_p">Available Balance</p>
                  <p class="blue-text-color">You can withdraw this now</p>
                </div>
              </li>
              <li className="balance-bg-blue">
                <div>
                  <h1 className="blue-text-color">£{totalPending?totalPending:"0.00"}</h1>
                  <p class="dasb_text_p">Pending Balance</p>
                </div>
              </li>
            </ul>
          </div> */}
        </div>
        <div className="col-xl-4">
          {/* <div className="availability-funds-right">
            <div className="withdraw-funds">
              <h4 class="blue-text-color">Withdraw Funds</h4>
              <img
                src={require("../../assets/images/download.svg").default}
                alt=""
                className="balance-icon-right"
              />
            </div>
          </div> */}
          {/* <div className="availability-funds-right">
            <div className="withdraw-funds">
              <h4>
                Export Selection
                <img
                  src={require("../../assets/images/send.svg").default}
                  alt=""
                  className="balance-icon-right"
                />
              </h4>
            </div>
          </div> */}
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="tbl_user_info">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                  <h4 className="title_text">Transactions</h4>
                  <div className="date-picker-part">
                    {/* <div className="date-label-input">Date</div>
                    <div className="date-input">
                      <input type="date" />
                    </div> */}
                  </div>
                </div>

                <div className="table-responsive height-fix-table">
                  <table className="table  vehicles_table  w-100 ">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Date</th>
                        <th>Client</th>
                        <th>Description</th>
                        <th>Status</th>
                        <th>Withdraw</th>
                        <th>Amount</th>
                      </tr>
                    </thead>

                    <tbody className="td_color">
                    {
                        walletList?.length>0?  walletList?.map((item,i)=>{
                            return  <tr key={i}>
                        <td>{i+1}</td>
                        <td>{moment(new Date(parseInt(item?.createdAt_transaction))).format("DD/MM/YYYY")}</td>
                        <td>Customer</td>
                        <td>N/A</td>
                        <td>{item?.payment_status}</td>
                        <td>{item?.withdrawl_status==0?"pending":"withdrawn"}</td>
                        <td>{item?.amount}</td>
                      </tr>
                        }):<td colspan="6"><p className="no_content_table">No Transactions</p></td>
                    }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Wallet;
