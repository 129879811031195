import React from "react";
import ReactApexCharts from "react-apexcharts";
export default function CustomerSignup() {
  const chartOptions = {
    // Define your chart options here
    chart: {
      height: 385,
      type: "line",
      backgroundColor: ["#2B3674"],
    },
    tooltip: {
      enabled: false,
    },
    labels: {
      show: false,
    },

    colors: ["#2B3674"],
    series: [
      {
        name: "Series 1",
        data: [0,0,0],
      },
    ],
    xaxis: {
      categories: ["monthly", "quarterly", "annually"],
    },
  };
  return (
    <div>
      {" "}
      <ReactApexCharts
        options={chartOptions}
        series={chartOptions.series}
        type="line"
        height={385}
      />
    </div>
  );
}
