import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
  updateIncorporationDate,
  uploadImage,
  verifyTradePerson,
} from "../../redux/Action/AdminAction";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import DeleteModal from "../Modals/DeleteModal";
import Select from "react-select";
import MultiSelect from "./MultiSelect";
import Badges from "./Badges";
import * as Yup from 'yup';
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
const Company = ({ viewDetail }) => {
  const [addService, setAddService] = useState(false);
  const [multiServices, setMultiServices] = useState([]);
  const [companyName, setCompanyName] = useState();
  const [verified, setVerified] = useState("");
  const [active, setActive] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [delId, setDeleteId] = useState();
  const [filePdf, setFilePdf] = useState();
  const [badge, setBadge] = useState();
  const { id } = useParams();
  const dispatch = useDispatch();
  const handleDropSelect = (name, type) => {
    name == "verified" ? setVerified(type) : setActive(type);
  };
  useEffect(() => {
    if (viewDetail?._id == id) {
      setVerified(viewDetail?.verified);
      setActive(viewDetail?.active);
      setMultiServices(viewDetail?.multi_services);
      setCompanyName(viewDetail?.company_name);
      setBadge(viewDetail?.badge);
    }
  }, [viewDetail]);
  const handleUpdateTardeP = () => {
    const dataObject = {
      tradesPerson_id: id,
      verified: verified,
      active: active,
    };
    dispatch(verifyTradePerson(dataObject));
  };
  const handleRemoveList = (list) => {
    const arr = multiServices?.filter((item, i) => {
      if (item?.label != list) return item;
    });
    setMultiServices(arr);
  };

  const handleDocsUpload = (e) => {
    const files = [];
    const fila = e.target.files;
    for (let key in fila) {
      files.push(fila[key]);
    }
    setFilePdf(files)
    const formData = new FormData();
    formData.append("id", id);
    files?.length > 0 &&
      files?.forEach((image) => {
        formData.append("trade_documents", image);
      });
    dispatch(updateIncorporationDate(formData, id));
  };

  const saveProfileDeatail = () => {
    const formData = new FormData();
    formData.append("company_name", companyName);
    formData.append("id", id);
    filePdf?.length > 0 &&
      filePdf?.forEach((image) => {
        formData.append("trade_documents", image);
      });
    dispatch(updateIncorporationDate(formData, id));
  }

  const handlePdfDawnload = (pdf) => {
    fetch(`${process.env.REACT_APP_BASE_URL}/business_image/${pdf}`).then(
      (response) => {
        response.blob().then((blob) => {
          const data = window.URL.createObjectURL(blob);
          let alink = document.createElement("a");
          alink.href = data;
          alink.download = pdf;
          alink.click();
        });
      }
    );
  };
  const [selectedBadges, setSelectedBadges] = useState([]);

  const toggleBadge = (label) => {
    if (selectedBadges.includes(label)) {
      setSelectedBadges(selectedBadges.filter((badge) => badge !== label));
    } else {
      setSelectedBadges([...selectedBadges, label]);
    }
  };

  const handleUpdateBadge = (e) => {

    setBadge(e.target.value)
    dispatch(
      updateIncorporationDate({
        badge: badge == e.target.value ? 0 : e.target.value,
        id: id,
      }, id)
    );
  }
  const handleDeselectBadge = (e) => {
    if (badge) {
      setBadge(0)
      dispatch(
        updateIncorporationDate({
          badge: 0,
          id: id,
        }, id)
      );
    }

  }

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const initialValues = {
    trade_documents: [
      {
        doc_title: '',
        doc_image: '',
      }
    ]
  };

  const validationSchema = Yup.object().shape({
    trade_documents: Yup.array().of(
      Yup.object().shape({
        doc_title: Yup.string().required('Document title is required'),
        doc_image: Yup.mixed().required('Document file is required')
      })
    )
  });

  const handleSubmit = async (values) => {
    console.log(values, "values");
    values.id = id;

    dispatch(updateIncorporationDate(values, id));
    handleClose()
  };
  return (
    <div className="row">
      <div className="col-md-9">
        <div className="row">
          <div className="col-xl-12">
            <div className="tbl_user_info">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative border-b-c">
                    <h4 className="title_text">Company Details</h4>
                  </div>
                  <div className="row">
                    <div className="col-md-8">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label for="" className="view-label-input">
                              Company Name
                            </label>
                            <div className="input-view-text">
                              {viewDetail?.company_name}
                            </div>
                            {/* <!-- <input type="text" className="form-control-new" placeholder=""> --> */}
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-group mb-3">
                            <label for="" className="view-label-input">
                              Incorporation Date
                            </label>

                            <input
                              type="date"
                              className="input-view-text border-none"
                              placeholder="dd/mm/yyyy"
                              name="date"
                              onChange={(e) => {
                                dispatch(
                                  updateIncorporationDate({
                                    id: id,
                                    incorporation_date: e.target.value,
                                  })
                                );
                              }}
                              value={viewDetail?.incorporation_date}
                            />
                            {/* {viewDetail?.date
                                ? moment(viewDetail?.date).format("DD/MM/YYYY")
                                : "N/A"} */}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label for="" className="view-label-input">
                              Enquiry Email
                            </label>
                            <div className="input-view-text">
                              {viewDetail?.business_email
                                ? viewDetail?.business_email
                                : "N/A"}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label for="" className="view-label-input">
                              Insurance Number
                            </label>
                            <div className="input-view-text">
                              {viewDetail?.insurance_no
                                ? viewDetail?.insurance_no
                                : "N/A"}
                            </div>
                          </div>

                        </div>

                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label for="" className="view-label-input">
                              Phone
                            </label>
                            <div className="input-view-text">
                              {viewDetail?.business_phnNumber
                                ? viewDetail?.business_phnNumber
                                : "N/A"}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label for="" className="view-label-input">
                              Insurance Expire
                            </label>
                            <div className="input-view-text">
                              {viewDetail?.insurance_expire
                                ? viewDetail?.insurance_expire
                                : "N/A"}
                            </div>
                          </div>

                        </div>
                      </div>

                    </div>
                    <div className="col-md-4">
                      <div className="form-group mb-3">
                        <label for="" className="view-label-input">
                          Business Address
                        </label>
                        {viewDetail?.business_locations[0]?.address
                          ?.split(",")
                          .filter((str) => str !== " ")
                          .map((item) => {
                            return (
                              <div className="form-group mb-3">
                                <div className="input-view-text">{item}</div>
                              </div>
                            );
                          })}
                        <div className="input-view-text">
                          {viewDetail?.business_locations[0]?.postcode
                            ? viewDetail?.business_locations[0]?.postcode
                            : "N/A"}
                        </div>
                      </div>
                      {/* {viewDetail?.business_locations[0]?
                    <>
                    {
                      viewDetail?.business_locations[0]?.address_1?  <div className="form-group mb-3">
                      <div className="input-view-text">
                          {viewDetail?.business_locations[0]?.address_1}
                        </div>
                      </div>:""
                    }
                  {
                    viewDetail?.business_locations[0]?.address_2? <div className="form-group mb-3">
                        <div className="input-view-text">
                          {viewDetail?.business_locations[0]?.address_2}
                        </div>
                      </div>:""
                  }
                     {
                      viewDetail?.business_locations[0]?.city?  <div className="form-group mb-3">
                        <div className="input-view-text">
                          {viewDetail?.business_locations[0]?.city}
                        </div>
                      </div>:""
                     }
                    {
                      viewDetail?.business_locations[0]?.country?    <div className="form-group mb-3">
                        <div className="input-view-text">
                          {viewDetail?.business_locations[0]?.country}
                        </div>
                      </div>:""
                    }
                  {
                    viewDetail?.business_locations[0]?.postcode? <div className="form-group mb-3">
                        <div className="input-view-text">
                          {" "}
                          {viewDetail?.business_locations[0]?.postcode}
                        </div>
                      </div>:''
                  }
                     
                    </>:<div className="input-view-text">N/A</div>
                   } */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {delId && (
          <DeleteModal
            showModal={showModal}
            setShowModal={setShowModal}
            id={delId}
            modalName="deleteTradeServices"
          />
        )}
        <div className="row">
          <div className="col-xl-6">
            <div className="tbl_user_info">
              <div className="card">
                <div className="card-body ad-height-280">
                  <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative border-b-c">
                    <h4 className="title_text">Services</h4>
                    {multiServices?.length > 0 && (
                      <button
                        onClick={() => {
                          setAddService(false);
                          dispatch(
                            updateIncorporationDate({
                              id: id,
                              multi_services: multiServices,
                            })
                          );
                        }}
                        className="btn btn-primary service-save-btn"
                      >
                        Save
                      </button>
                    )}
                  </div>
                  <div className="row overflow_prop">
                    {multiServices ? (
                      multiServices?.map((item, i) => {
                        return (
                          <div className="col-md-12" key={i}>
                            <div className="form-group mb-3">
                              <div className="bg-input-shadow-c">
                                <p>{item?.label}</p>
                                <div
                                  onClick={() => handleRemoveList(item?.label)}
                                  className="close-icon-red"
                                >
                                  <img
                                    src={
                                      require("../../assets/images/red_close.svg")
                                        .default
                                    }
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="col-md-12">
                        <div className="form-group mb-3">
                          <div className="bg-input-shadow-c">
                            <p>N/A</p>
                            <div className="close-icon-red">
                              <img src="assets/images/red_close.svg" alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="add-services-btn">
                    {addService == false ? (
                      <button
                        onClick={() => setAddService(true)}
                        className="btn btn-primary btn-primary-lg border-radius-btn-c d-flex justify-content-center ms-auto mt-3"
                      >
                        Add Services
                      </button>
                    ) : (
                      <MultiSelect
                        setMultiServices={setMultiServices}
                        multiServices={multiServices}
                        type="profile"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="tbl_user_info">
              <div className="card">
                <div className="card-body ad-height-280">
                  <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative border-b-c">
                    <h4 className="title_text">Documents</h4>
                    <div className="upload__btn-box">
                      <label className="upload__btn" onClick={handleShow}>
                        <p>Upload</p>

                      </label>
                    </div>
                  </div>
                  <div className={viewDetail?.trade_documents?.length > 0 ? "overflow_prop-170" : "overflow_prop-170 no_data_scroll"}>

                    {
                      viewDetail?.trade_documents?.length > 0 ?
                        viewDetail?.trade_documents.map((item, i) => {

                          const arr = item?.doc_image?.split(".")
                          const extensionType = arr?.slice(-1)[0]

                          return <div style={{ "cursor": "pointer" }} onClick={() => {
                            window.open(process.env.REACT_APP_IMG_URL + `/business_image/${item?.doc_image}`, '_blank').focus();
                          }} className="row">
                            <div className="col-md-12">
                              <div className="form-group mb-3">
                                <div className="bg-input-shadow-c">
                                  <div className="group-icon-text justify-content-between">
                                    <div className="d-flex">
                                      <img src={require("../../assets/images/tick_mark.svg").default} />
                                      <p>{item?.doc_title}</p>
                                    </div>
                                    <p className="padf">{extensionType}</p>

                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>
                        }) : <p className="no_data">N/A</p>
                    }
                    {/* <div className="col-md-12">
                      <div className="form-group mb-3">
                        <div className="bg-input-shadow-c">
                          <div className="group-icon-text">
                            <img src="assets/images/doc.svg" alt="" />
                            <p>Insurance</p>
                          </div>
                          <div className="pdf-text">PDF</div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group mb-3">
                        <div className="bg-input-shadow-c">
                          <div className="group-icon-text">
                            <img src="assets/images/doc.svg" alt="" />
                            <p>Identification</p>
                          </div>
                          <div className="pdf-text">PDF</div>
                        </div>
                      </div>
                    </div> */}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          {/* <div className="col-xl-6">
            <div className="tbl_user_info">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative border-b-c">
                    <h4 className="title_text">Company Details</h4>
                    <button
                        onClick={() => {
                          saveProfileDeatail()
                        }}
                        className="btn btn-primary service-save-btn"
                      >
                        Save
                      </button>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group mb-3">
                            <label for="" className="view-label-input">
                              Name
                            </label>
                            <input
                              type="text"
                              className="form-control-new"
                              placeholder="Name"
                              onChange={(e) => setCompanyName(e.target.value)}
                              value={companyName}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="upload__box">
                        <label for="" className="view-label-input">
                          Upload Documents
                        </label>
                        <div className="upload__btn-box">
                          <label className="upload__btn">
                            <p>Upload</p>
                            <input
                              type="file"
                              multiple
                              accept="application/pdf"
                              name="images[]"
                              data-max_length="4"
                              className="upload__inputfile"
                              onChange={handleDocsUpload}
                            />
                          </label>
                        </div>
                        <div className="upload__img-wrap"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div className="col-xl-6">
            <div className="tbl_user_info">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative border-b-c">
                    <h4 className="title_text">Profile</h4>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group mb-3">
                        <label for="" className="view-label-input">
                          Bio
                        </label>

                        <div className="input-view-text height140 text_scroll text-message">
                          {viewDetail?.bio}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="row">
          <div className="col-12">
            <div className="user_profile">
              {viewDetail?.business_image ? (
                <img
                  src={
                    process.env.REACT_APP_IMG_URL +
                    "/business_image/" +
                    viewDetail?.business_image
                  }
                  alt=""
                />
              ) : (
                <img
                  src={require("../../assets/images/placeholder_img.png")}
                  alt=""
                  className=""
                />
              )}

              <div className="dropdown_items">
                <div className="dropdown_custom ">
                  <div className="dropdown_main">
                    <Dropdown>
                      <Dropdown.Toggle
                        variant=""
                        id="dropdown-basic"
                        className="p-0 btn-custom-drop dropdown-toggle"
                      >
                        {verified == 0 ? "Unverified" : "Verified"}
                        <i class="mdi mdi-menu-down"></i>
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="w-100">
                        <Dropdown.Item
                          onClick={() => handleDropSelect("verified", 1)}
                        >
                          Verified
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => handleDropSelect("verified", 0)}
                        >
                          Unverified
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <div className="dropdown_custom ">
                  <div className="dropdown_main">
                    <Dropdown>
                      <Dropdown.Toggle
                        variant=""
                        id="dropdown-basic"
                        className="p-0 btn-custom-drop dropdown-toggle"
                      >
                        {active == 0 ? "Inactive" : "Active"}
                        <i class="mdi mdi-menu-down"></i>
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="w-100">
                        <Dropdown.Item
                          onClick={() => handleDropSelect("active", 1)}
                        >
                          Active
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => handleDropSelect("active", 0)}
                        >
                          Inactive
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>

                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                      style={{ margin: "0px" }}
                    >
                      <a className="dropdown-item" href="#">
                        {" "}
                        Active
                      </a>
                    </div>
                  </div>
                </div>
                <button
                  onClick={handleUpdateTardeP}
                  className="btn btn-primary btn-primary-lg border-radius-btn-c d-flex justify-content-center mx-auto"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
          <div className="col-xl-12">
            <div className="tbl_user_info mt-5">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center mb-3 position-relative border-b-c select-badges">
                    {/* <h2>Select Badges</h2> */}

                    <h4 className="title_text">Select Badges</h4>
                    <button type="button" className="btn btn-primary btn-primary-lg border-radius-btn-c d-flex justify-content-center mx-auto" onClick={handleDeselectBadge}>Deselect</button>
                  </div>
                  <div className="row">
                    <div className="imagesd-radios">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          id="flexRadioDefault"
                          name="badge"
                          onChange={(e) => handleUpdateBadge(e)}
                          value={1}
                          checked={badge == 1}

                        />
                        <label class="form-check-label" for="flexRadioDefault">
                          <img src={require("../../assets/images/platinum_rated.png")} />
                        </label>
                      </div>
                    </div>
                    <div className="imagesd-radios">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          id="flexRadioDefault1"
                          name="badge"
                          onChange={(e) => handleUpdateBadge(e)}
                          value={2}
                          checked={badge == 2}
                        />
                        <label class="form-check-label" for="flexRadioDefault1">
                          <img src={require("../../assets/images/diamond_rated.png")} />
                        </label>
                      </div>
                    </div>
                    <div className="imagesd-radios">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="badge"
                          id="flexRadioDefault2"
                          value={3}
                          checked={badge == 3}
                          onChange={(e) => handleUpdateBadge(e)}
                        />
                        <label class="form-check-label" for="flexRadioDefault2">
                          <img src={require("../../assets/images/gold_rated.png")} />
                        </label>
                      </div>
                    </div>
                    {/* {['Badge 1', 'Badge 2', 'Badge 3'].map((label) => (
                      <Badges
                        key={label}
                        label={label}
                        selected={selectedBadges.includes(label)}
                        onClick={toggleBadge}
                      />
                    ))} */}
                    {/* <div className="col-md-12">
                      <div className="form-group mb-3">
                        <div className="bg-input-shadow-c">
                          <p>N/A</p>
                          <div className="close-icon-red">
                            <img src="assets/images/red_close.svg" alt="" />
                          </div>
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="col-md-12">
                      <div className="form-group mb-3">
                        <div className="bg-input-shadow-c">
                          <p>N/A</p>
                          
                          <div className="close-icon-red">
                            <img src="assets/images/red_close.svg" alt="" />
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Documents</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="documents-main-form">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue, isSubmitting }) => (
                <Form>
                  <FieldArray name="trade_documents">
                    {({ insert, remove, push }) => (
                      <div>
                        {values.trade_documents.length > 0 &&
                          values.trade_documents.map((doc, index) => (
                            <div className="documents-upload" key={index}>
                              <div className="form-group">
                                <label>Document Title</label>
                                <Field name={`trade_documents[${index}].doc_title`} type="text" className="form-control" placeholder="Type Here...." />
                                <ErrorMessage name={`trade_documents[${index}].doc_title`} component="div" className="field-error" />
                              </div>
                              <div className="upload-input">
                                <input
                                  name={`trade_documents[${index}].doc_image`}
                                  type="file"
                                  className="form-control"
                                  //accept="application/pdf"
                                  onChange={(event) => {
                                    const formData = new FormData();
                                    formData.append('image', event.currentTarget.files[0]);
                                    dispatch(uploadImage(formData)).then((item) => {
                                      setFieldValue(`trade_documents[${index}].doc_image`, item?.data?.filename);
                                    });

                                  }}
                                />
                                <ErrorMessage name={`trade_documents[${index}].doc_image`} component="div" className="field-error" />
                              </div>
                              <button type="button" onClick={() => remove(index)} className="cmn-doc">
                                Remove
                              </button>
                            </div>
                          ))}
                        <button type="button" className="cmn-doc" onClick={() => push({ doc_title: '', doc_image: '' })}>
                          Add Document <img src={require("../../assets/images/add.png")} />
                        </button>
                      </div>
                    )}
                  </FieldArray>
                  <div className="d-flex justify-content-center">
                    <button type="submit" className="cmn-doc" disabled={isSubmitting}>Submit</button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal.Body>

      </Modal>
    </div>
  );
};

export default Company;
