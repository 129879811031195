import React from "react";
import ReactApexCharts from "react-apexcharts";
export default function TradepersonLoginTimes() {
  const chartOptions = {
    // Define your chart options here
    chart: {
      height: 385,
      type: "line",
      backgroundColor: ["#2B3674"],
    },
    tooltip: {
      enabled: false,
    },
    labels: {
      show: false,
    },

    colors: ["#2B3674"],
    series: [
      {
        name: "Series 1",
        data: [0,0,0,0,0,0,0,0],
      },
    ],
    xaxis: {
      categories: [
        "08:00 hrs to 10:00 hrs",
        "10:00 hrs to 12:00 hrs",
        "12:00 hrs to 15:00 hrs ",
        "15:00 hrs to 18:00 hrs",
        "18:00 hrs to 20:00 hrs",
        "20:00 hrs to 22:00 hrs ",
        "22:00 hrs to 00:00 hrs ",
        "00:00 hrs to 06:00 hrs ",
        "06:00 hrs to 08:00 hrs ",
      ],
    },
  };
  return (
    <div>
      {" "}
      <ReactApexCharts
        options={chartOptions}
        series={chartOptions.series}
        type="line"
        height={385}
      />
    </div>
  );
}
