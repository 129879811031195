import React from "react";
import ReactApexCharts from "react-apexcharts";
export default function CustomerLength() {
  const chartOptions = {
    // Define your chart options here
    chart: {
      height: 385,
      type: "line",
      backgroundColor: ["#2B3674"],
    },
    tooltip: {
      enabled: false,
    },
    labels: {
      show: false,
    },

    colors: ["#2B3674"],
    series: [
      {
        name: "Series 1",
        data: [0,0,0,0,0,0,0,0,0,0],
      },
    ],
    xaxis: {
      categories: [
        "0-20 sec",
        "10:00 hrs to 12:00 hrs",
        "40-60 sec ",
        "1 min to 5 min",
        "5 min to 10 min",
        "10 min to 15 min",
        "15 min to 20 min",
        "20 min to 30 min",
        "30 min to 45 min",
        "45 min plus ",
      ],
    },
  };
  return (
    <div>
      {" "}
      <ReactApexCharts
        options={chartOptions}
        series={chartOptions.series}
        type="line"
        height={385}
      />
    </div>
  );
}
