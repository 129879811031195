import React from 'react';

function Badges({ label, selected, onClick }) {
  return (
    <div
      className={`badge ${selected ? 'selected' : ''}`}
      onClick={() => onClick(label)}
    >
      {label}
    </div>
  );
}

export default Badges;